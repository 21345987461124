







































































import Vue from 'vue';
import Component from 'vue-class-component';
import { User, Global } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import Utils from '@/modules/Utils';
import { Prop, Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PropEditor from '@/components/PropEditor.vue';
import { updateUserProfile } from '@/modules/ApiUsers';

@Component({
    components: {
        ConfirmDialog,
        PropEditor
    }
})

export default class EditUserProfileDialog extends Vue {

    @User.State('user_profile') user_profile;
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;

    @Prop() dialog;

    editable_user_profile = null;

    mounted(){
        this.editable_user_profile = cloneDeep(this.user_profile);
    }

    get form_fields() {
        return this.editable_user_profile 
            ?   [
                    {text: 'First Name', value: 'first_name', type: 'textfield', required: true, rules: [(value) => !!value || this.$t('Required'), Utils.isValidName(this.editable_user_profile.first_name, 'First')]},
                    {text: 'Last Name', value: 'last_name', type: 'textfield', required: true, rules: [(value) => !!value || this.$t('Required'), Utils.isValidName(this.editable_user_profile.last_name, 'Last')]},
                    {text: 'Phone Number', value: 'phone_number', type: 'tel', required: true, rules: [Utils.isValidPhoneNumber(this.editable_user_profile.phone_number)]}
                ]
            :   [];
    }

    get dialog_max_width() {
        return this.$vuetify.breakpoint.xsOnly 
            ? '80vw' 
            : this.$vuetify.breakpoint.smOnly 
                ? '50vw' 
                : this.$vuetify.breakpoint.mdOnly 
                    ? '40vw' 
                    : this.$vuetify.breakpoint.lgOnly 
                        ? '30vw' 
                        : '25vw';
    }

    @Watch('editable_user_profile', {deep: true, immediate: true})
    checkDisabledUpdate(){
        return this.$refs.form && !(this.$refs.form as Vue & { validate: () => boolean }).validate() || !this.$refs.form
            ? true
            : false;
    }
    
    async editProfile() {
        this.form_fields
            .filter((field) => field.type === 'textfield')
            .forEach((field) => this.editable_user_profile[field.value] = this.editable_user_profile[field.value].trim());

        const user_data = {
            first_name: this.editable_user_profile.first_name,
            last_name: this.editable_user_profile.last_name,
            phonenumber: this.editable_user_profile.phone_number,
            multi_factor_auth: `${this.editable_user_profile.multi_factor_auth}`,
            multi_factor_auth_method: this.editable_user_profile.multi_factor_auth_method ||'Email',
            email: this.editable_user_profile.email
        }

        const succeeded = await updateUserProfile(this.editable_user_profile.company, this.editable_user_profile.id, user_data);
        if (succeeded) {
            this.throwNotify({
              type: 'success',
              title: this.$t('Success'),
              text: this.$t(`User data updated successfully`)
            });
        }else {
            this.throwNotify({
              type: 'error',
              title: this.$t('Error'),
              text: this.$t(`Some of the user's information is not saved`)
            });
        }

        this.$emit('close');
    }
}
