var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{class:_vm.$route.path.includes('page-not-found') && !_vm.project ? '' : 'background-linear',attrs:{"id":"app","data-app":"true"}},[(_vm.isFirstLoading || _vm.isMapLoading)?_c('div',{staticClass:"d-flex align-center justify-center",attrs:{"id":"loader"}},[_c('v-progress-circular',{attrs:{"indeterminate":"indeterminate","color":"blue","size":"48"}})],1):_vm._e(),_c('Notifications'),(_vm.$route.path !== '/sign' && _vm.$route.path !== '/projects')?_c('Sidebar'):_vm._e(),(_vm.$route.path.includes('page-not-found') && !_vm.project)?_c('Error404'):_vm._e(),_c('MessageDialog',{attrs:{"dialog":_vm.show_not_allowed_user_dialog,"message":_vm.not_allowed_user_message},on:{"dialogClosed":_vm.onNotAllowedUserDialogClosed}}),(_vm.show_user_profile_dialog && _vm.project)?_c('EditUserProfileDialog',{attrs:{"dialog":_vm.show_user_profile_dialog},on:{"close":function($event){_vm.show_user_profile_dialog = false}}}):_vm._e(),_c('v-layout',{staticClass:"d-flex flex-row",style:(_vm.contentStyles),attrs:{"id":"content-wrapper","column":""}},[(_vm.$route.path !== '/sign')?_c('Header',{attrs:{"time":_vm.time}}):_vm._e(),(
          !_vm.isMapLoading &&
          (_vm.$route.path.includes('sign') ||
            _vm.$route.path === '/projects' ||
            _vm.$route.path === '/companies' ||
            _vm.$route.path.includes('/authentication/reset_password') ||
            _vm.project)
        )?_c('div',{style:(_vm.$route.meta.header !== false && !_vm.$route.path.includes('/authentication/reset_password') 
          ? 'max-height: calc(100vh - 80px)' 
          : ''),attrs:{"id":"content"}},[_c('div',{staticClass:"scroller",class:{ extended: !_vm.isSidebarOpen }},[_c('div',{class:_vm.containerStyles},[_c('router-view')],1)])]):_vm._e()],1),(_vm.notification.length > 0 && _vm.$route.path !== '/sign' && !_vm.$route.path.includes('/authentication/reset_password'))?_c('div',[_c('v-card',{staticClass:"notificationDiv",staticStyle:{"background-color":"white !important"},style:(_vm.lang !== 'he' 
          ? 'z-index: 9999; position: absolute; bottom: 0; left: 300px' 
          : 'z-index: 9999; position: absolute; bottom: 0; right: 300px')},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"notificationHeader",attrs:{"xs12":""}},[_c('div',{staticClass:"pt-1 text-white"},[_vm._v(_vm._s(_vm.$t('Tondo Notification')))]),_c('v-icon',{staticClass:"notificationIcon",attrs:{"color":"white"},on:{"click":_vm.closeNotification}},[_vm._v("mdi-close")])],1),_c('v-flex',{staticClass:"notificationMessageDiv my-4",attrs:{"xs12":""}},[_vm._v(" "+_vm._s(_vm.notification)+" ")]),_c('v-flex',{staticClass:"mb-4",attrs:{"xs12":""}},[_c('v-btn',{staticClass:"center",attrs:{"color":"error"},on:{"click":_vm.closeNotification}},[_vm._v(_vm._s(_vm.$t('Close')))])],1)],1)],1)],1):_vm._e()],1),_c('GlobalSnackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }