import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import store from 'store';

import vuex from '..';
import Utils from '../../modules/Utils';
import router from '../../router';
import API, { Types } from '../../modules/API';
import Vue from 'vue';
import moment from 'moment-timezone';
import { setUserProperty } from '@/modules/ApiUsers.js';
import MQTT from '@/modules/MQTT';

@Module({ namespaced: true })
export default class User extends VuexModule {
  error = '';
  isAuth = false;
  role = '';
  verified = '';
  username = '';
  user_id = '';
  isManager = false;
  isLoading = true;
  idleIntervalActive = false;
  cabinet = {};
  cabinets = [];
  project = store.get('project');
  firstnotificationTime = 0;
  notifications = [];
  mqtt = MQTT.instance;
  properties = [];
  default_map = '';
  user_profile = null;

  @Action async init(data = store.get('user')) {
    vuex.commit('Projects/setAlerts', {});
    vuex.commit('Projects/setEvents', {});

    const isAuthPassed = data && data.apikey;
    if (!isAuthPassed) {
      vuex.commit('User/setIsLoading', false);
      return;
    }
    if (window.location.pathname === '/sign') {
      localStorage.setItem('last_time_seen', moment().utcOffset(0).valueOf().toString());
    }
    vuex.commit('User/setUserData', data);
    vuex.commit('User/listenToEvents');
    vuex.commit('User/generalDataUpdateEveryMinute');
    vuex.commit('User/setLogoutInterval');
  }

  @Action
  fetchProjectsData(){
    vuex.dispatch('Global/loadDeviceClasses');
    vuex.dispatch('Projects/load');
    vuex.dispatch('User/setUserNotificationTime');
  }

  @Action
  async fetchUserProperties(){
    const response = await API.get(Types.PROPS, '');
    if (response && response.results) vuex.commit('User/setUserProperties', response.results);
  }

  @Action
  async fetchUserProfile(){
    const user = store.get('user');
    const response = await API.get(Types.COMPANIES, `${user.company}/users/profile/`);
    if (response) vuex.commit('User/setUserProfile', response);
  }

  @Action
  projectDataUpdateEveryMinute() {
    const user = store.get('user') || null;
    if (!user || !user.company) return;

    if (this.firstnotificationTime){
      vuex.dispatch('User/getProjectNotifications');
    }
    vuex.dispatch('Projects/getAlerts');
    vuex.dispatch('Projects/getEvents');
    vuex.commit('Global/seHasElectricalPower', Utils.hasElectricalPower());
  }

  @Action
  async updateUserProperty(prop_data){
    const state = (vuex.state as any);
    try {
      await API.post(Types.PROPS, '',
        prop_data.value,
        { query: {name: prop_data.name, user: state.User.user_id} }
      );
      vuex.dispatch('User/fetchUserProperties');
    }catch (e){}
  }

  @Mutation
  setUserProperties(properties){
    this.properties = properties;
  }

  @Mutation
  setUserProfile(profile){
    this.user_profile = profile;
  }

  @Mutation
  setDefaultMap(default_map){
    this.default_map = default_map;
  }

  @Action async setUserNotificationTime() {
    if (localStorage.getItem('user')) {
      await API.get(Types.PROPS, '').then(async (res) => {
        if (res && res.results) {
          const prop = res.results.find(
            (p) => p.name === 'dashboard.notifications'
          );
          if (
            prop &&
            prop.value &&
            prop.value.hasOwnProperty('last_notification_seen')
          ) {
            vuex.commit(
              'User/setLastNotificationTime',
              prop.value.last_notification_seen
            );
          } else {
            await setUserProperty('dashboard.notifications', {
              last_notification_seen: 0
            });
            vuex.commit('User/setLastNotificationTime', 0);
          }
        } else {
          await setUserProperty('dashboard.notifications', {
            last_notification_seen: 0
          });
          vuex.commit('User/setLastNotificationTime', 0);
        }
      });
    }
  }

  @Action async getProjectNotifications() {
    let time = vuex.state['User'].firstnotificationTime;
    const project = store.get('project');
    if (time === 0) {
      await API.get(Types.PROPS, '')
        .then(async (props) => {
          if (props && props.results) {
            const prop = props.results.find(
              (p) => p.name === 'dashboard.notifications'
            );
            if (prop) {
              time = prop.value.last_notification_seen;
              vuex.commit(
                'User/setLastNotificationTime',
                prop.value.last_notification_seen
              );
            } else {
              await setUserProperty('dashboard.notifications', {
                last_notification_seen: 0
              });
              vuex.commit('User/setLastNotificationTime', 0);
            }
          } else {
            await setUserProperty('dashboard.notifications', {
              last_notification_seen: 0
            });
            vuex.commit('User/setLastNotificationTime', 0);
          }
        })
        .then(() => {
          if (
            project &&
            project.hasOwnProperty('notificationStreamId') &&
            project.notificationStreamId !== ''
          ) {
            API.get(
              Types.COMPANIES,
              `${project.company}/projects/${project.id}/streams/${
                project.notificationStreamId
              }/history/?from=${
                time && time !== 0 ? time : new Date().getTime() - 86400000
              }`
            ).then((res) => {
              const notifications = [];
              for (const notification of res) {
                notifications.push({
                  message: JSON.parse(notification[1]).value,
                  time: notification[0]
                });
              }
              vuex.commit(
                'User/setNotifications',
                notifications.filter((n) => n.time > time)
              );
            });
          }
        });
    } else if (time) {
      if (
        project &&
        project.hasOwnProperty('notificationStreamId') &&
        project.notificationStreamId !== ''
      ) {
        API.get(
          Types.COMPANIES,
          `${project.company}/projects/${project.id}/streams/${
            project.notificationStreamId
          }/history/?from=${
            time && time !== 0 ? time : new Date().getTime() - 86400000
          }`
        ).then((res) => {
          const notifications = [];
          for (const notification of res) {
            notifications.push({
              message: JSON.parse(notification[1]).value,
              time: notification[0]
            });
          }
          vuex.commit(
            'User/setNotifications',
            notifications.filter((n) => n.time > time)
          );
        });
      }
    }
  }

  @Mutation
  setError(msg) {
    this.error = msg;
  }

  @Mutation
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @Mutation
  setManager(isManager) {
    this.isManager = isManager;
  }

  @Mutation
  setIntervalActive(is_active) {
    this.idleIntervalActive = is_active;
  }

  @Mutation
  updateDataEveryMinute() {
    vuex.commit('User/generalDataUpdateEveryMinute');
    vuex.dispatch('User/projectDataUpdateEveryMinute');
  }

  @Mutation
  setLogoutInterval(){
    if (!this.idleIntervalActive) {
      vuex.commit('User/setIntervalActive', true);
      const idleInterval = setInterval(() => {
        if (!this.idleIntervalActive) {
          clearInterval(idleInterval);
          return;
        }else if (Utils.getIdleTimeInMinutes() >= 30) {
          vuex.commit('User/setIntervalActive', false);
          vuex.commit('User/logout');
          clearInterval(idleInterval);
          return;
        }
        vuex.commit('User/updateDataEveryMinute', moment().utcOffset(0).valueOf());
      }, 60000); // run every minute
    }
  }

  @Mutation
  generalDataUpdateEveryMinute(){
    const now = moment().utcOffset(0).valueOf();
    localStorage.setItem('now', now.toString());
    const day_of_week = moment(+localStorage.getItem('now')).day() + 1;
    if ((vuex.state as any).Global.day_of_week !== day_of_week) {
      vuex.commit('Global/setDayOfWeek', day_of_week);
    }
  }

  @Mutation
  listenToEvents() {
    window.addEventListener('load', Vue.prototype.$handler, true);
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach((name) => {
      document.addEventListener(name, Vue.prototype.$handler, true);
    });
  }

  @Mutation
  removeListeners() {
    window.removeEventListener('load', Vue.prototype.$handler, true);
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach((name) => {
      document.removeEventListener(name, Vue.prototype.$handler, true);
    });
  }

  @Mutation
  setUserData(data) {
    if (!data) return;
    const prev_data = store.get('user') || {};
    const user_data = { ...prev_data, ...data};

    if (user_data.allowed_companies && user_data.allowed_companies.length === 1){
      user_data.company = user_data.allowed_companies[0];
    }

    store.set('user', user_data);

    this.username = data.username;
    this.verified = data.verified;
    this.role = data.role;
    this.isAuth = true;
    this.user_id = data.id.toString();
  }

  @Mutation
  clearProject() {
    vuex.commit('MapSettings/setDeviceRepositioning', false);
    vuex.commit('User/setDefaultMap', '');
    vuex.commit('Groups/flush');
    vuex.commit('Props/flush');
    vuex.commit('Reports/clearReports');
    vuex.commit('Reports/clearDevicesCount');
    vuex.commit('Reports/clearCabinetsCount');
    vuex.commit('User/unsetProject');
    vuex.commit('Global/setTimezone', null);
    this.mqtt.disconnect();
  }

  @Mutation
  clearProjects(){
    vuex.commit('Projects/flush');
    vuex.commit('Projects/clearProjectsData');
    vuex.commit('Global/setMqttVersions', new Map());
  }

  @Mutation
  clearUser(){
    store.remove('user');
    this.username = '';
    this.user_id = '';
    this.isAuth = false;
    this.isManager = false;
  }

  @Mutation
  clearCompanyData(){
    vuex.commit('User/clearProject');
    vuex.commit('User/clearProjects');
  }

  @Mutation
  logout() {
    vuex.commit('User/clearCompanyData');
    vuex.commit('User/clearUser');
    vuex.commit('Companies/setCompaniesList', {});
    vuex.commit('User/setIntervalActive', false);
    vuex.commit('User/removeListeners');
    vuex.commit('User/setUserProperties', []);
    vuex.commit('Global/setIsSidebarOpen', true);
    vuex.commit('User/setUserProfile', null);
    
    if (localStorage.getItem('filtered_alerts_url')) {
      localStorage.removeItem('filtered_alerts_url');
    }
    router.push('/sign');
  }

  @Mutation
  selectProject(data = store.get('project')) {
    if (!data || !data.id) return;
    store.set('project', data);
    this.project = data;
  }

  @Mutation
  selectCabinets(data) {
    this.cabinets = data;
  }

  @Mutation
  selectCabinet(data) {
    this.cabinet = data;
  }

  @Mutation
  unsetProject() {
    store.remove('project');
    this.project = null;
  }

  @Mutation
  removeIncident(ticket_data) {
    const cabinet = this.cabinets.find((cabinet) => cabinet.cabinetNumber === ticket_data.cabinet_id);
    if (cabinet) {
      cabinet[ticket_data.incidents_type] = cabinet[ticket_data.incidents_type].filter((incident) => incident.id.toString() !== ticket_data.id);
    }
  }

  @Mutation
  setLastNotificationTime(time) {
    this.firstnotificationTime = time;
  }

  @Mutation
  setNotifications(data) {
    this.notifications = data.filter(
      (notification) => notification.time > this.firstnotificationTime
    );
  }

  @Mutation
  closeNotification(time) {
    this.firstnotificationTime = time + 1;
    this.notifications.splice(0, 1);
    setUserProperty('dashboard.notifications', {
      last_notification_seen: time + 1
    });
  }
}
