import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import vuex from '..';
import findIndex from 'lodash/findIndex';
import API from '@/modules/API';
import get from 'lodash/get';

export interface Curve {
  id: string;
  name: string;
  description: string;
  start: object;
  end: object;
  chunks: any;
  color: string;
}

export interface Schedule {
  id: string;
  name: string;
  description: string;
  groups: any;
  curves: any;
}

@Module({ namespaced: true })
export default class Schedules extends VuexModule {
  isLoading = false;
  userCurves: Curve[] = [];
  schedules: Schedule[] = [];

  @Action
  setLoading(data: boolean) {
    vuex.commit('Schedules/setLoaderState', data);
  }

  @Action
  async runSchedulerInServer() {
    const company_id = (vuex.state as any).User.project.company;
    const project_id = (vuex.state as any).User.project.id;

    try {
      const url = 'groups_scheduler/';
      const body = {
        company_id,
        project_id
      };
      const response = await API.backendServicesAPI(url, 'POST', {}, body);
    } catch (e) {
      console.log(e, `Could not run schedule update in server`);
    }
  }

  @Action
  async loadUserCurves() {
    try {
      vuex.commit('Schedules/setUserCurves', []);
      // @ts-ignore
      const curves = vuex.state.Props.list.find(
        (item) => item.name === 'dashboard.curves'
      );
      if (curves) {
        vuex.commit('Schedules/setUserCurves', curves);
      }
    } catch (e) {
      vuex.commit(
        'Errors/setError',
        get(e, 'response.data.error.message', e)
      );
      vuex.commit('Errors/setShowError', true);
    }
  }

  @Action
  async loadSchedules(data) {
    try {
      vuex.commit('Schedules/setSchedules', []);
      if (!(vuex.state as any).Props.list.length) {
        await vuex.dispatch('Props/load', data);
      }
      // @ts-ignore
      const schedules = vuex.state.Props.list.find(
        (item) => item.name === 'dashboard.schedules'
      );
      if (schedules) {
        vuex.commit('Schedules/setSchedules', schedules);
      }
    } catch (e) {
      vuex.commit(
        'Errors/setError',
        get(e, 'response.data.error.message', e)
      );
      vuex.commit('Errors/setShowError', true);
    }
  }

  @Action
  deleteCurve(curveId) {
    vuex.commit('Schedules/dropCurve', curveId);
  }

  @Action
  changeCurve(data: any) {
    vuex.commit('Schedules/updateCurve', data);
  }

  @Mutation
  updateCurve(data: any) {
    const curveIndex = this.userCurves.findIndex(
      (curve) => curve.id === data.id
    );
    curveIndex > -1
      ? this.userCurves.splice(curveIndex, 1, data)
      : this.userCurves.push(data);
  }

  @Mutation
  dropCurve(curveId) {
    const curveIndex = this.userCurves.findIndex(
      (curve) => curve.id === curveId
    );
    if (curveIndex > -1) this.userCurves.splice(curveIndex, 1);
  }

  @Mutation
  setLoaderState(data: boolean) {
    this.isLoading = data;
  }

  @Mutation
  setUserCurves(data: any) {
    let curves = [];
    if (!data) {
      return;
    }
    if (Array.isArray(data.value)) {
      curves = data.value.slice();
    }
    this.userCurves = curves;
  }

  @Mutation
  setSchedules(data: any) {
    let schedules = [];
    if (!data) {
      return;
    }

    if (data.length === 0) {
      return (this.schedules = []);
    }

    if (Array.isArray(data.value)) {
      schedules = data.value.slice();
    }
    this.schedules = schedules;
  }

  @Action
  updateExistSchedule(data: any) {
    vuex.commit('Schedules/updateSchedule', data);
  }

  @Mutation
  updateSchedule(data: any) {
    const ind = findIndex(this.schedules, (item) => item.id === data.id);
    if (ind > -1) {
      this.schedules.splice(ind, 1, data);
    } else {
      this.schedules.push(data);
    }
    return this.schedules;
  }
}
