import moment from 'moment';
import momentTZ from 'moment-timezone';
import store from 'store';
import vuex from '../store';
import Vue from 'vue';
import { i18n } from '@/main';
import API, { Types } from '@/modules/API';
import PropUtils from './PropUtils';

export default class Utils {
  static setLoading(flag) {
    vuex.commit('Global/setLoading', flag);
  }

  static ucfirst(str = '') {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  static find(array, value, key = 'id') {
    let index = -1;
    array.forEach((el, i) =>
      String(el[key]) === String(value) ? (index = i) : false
    );
    return index;
  }

  static convertToValidJson(string_json){
    return string_json.replaceAll(`'`, `"`).replaceAll(`True`, `true`).replaceAll(`False`, `false`);
  }
  
  static getDateString(date = new Date(), format = 'DD.MM.YYYY HH:mm') {
    return moment(date).format(format);
  }

  static exitFullscreen(document: any) {
    const isInFullScreen = document.fullscreenElement;

    if (isInFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  static setDocumentTitle(route, project: any = {}) {
    if (!route) return;

    let route_name = route.meta.title || route.name || '';
    if (!route_name && route.path.includes('custom')) {
      route_name = (vuex.state as any).MapSettings.userMapFilters.find((map_filter) => map_filter.path === route.path);
      route_name = route_name && `filter ${route_name.name}` || '';
    }

    const prefix =  project && project.name || 'Tondo Smart Light';
    let title = Utils.ucfirst(prefix) + ' | ' + route_name;
    if (route.params.id) title += ' ' + route.params.id;

    document.title = title;
  }

  static showNotify(type, title, text) {
    (Vue as any).notify({ type, title, text });
  }

  static setDocumentFavicon(projectID, props) {
    if (!projectID || !props) return;

    let logo, favicon;
    props.forEach((p) => {
      if (p.objectID !== projectID) return;
      if (p.name === 'meta.logo') logo = p.value.url;
      else if (p.name === 'meta.favicon') favicon = p.value.url;
    });

    const href = favicon
      ? favicon
      : logo
      ? logo.includes('image/png') || logo.includes('image/jpeg')
        ? logo
        : '/favicon.png'
      : '/favicon.png';
    document.querySelector('#favicon').setAttribute('href', href);
  }

  static getDateWithSpecificTime(time_string, date) {
    const time_object = this.getHourAndMinutes(time_string);
    return moment(date)
      .utcOffset(0)
      .set(time_object)
      .valueOf();
  }

  static getHourAndMinutes(time_string) {
    const [hour, minute] = time_string.split(':');
    return { hour: +hour, minute: +minute, second: 0, millisecond: 0 };
  }

  static hasElectricalPower() {
    const electrical_power_window = (vuex.state as any).Global.electricalPowerWindow;

    return !(
      !electrical_power_window ||
      (electrical_power_window.start &&
        electrical_power_window.end &&
        +localStorage.getItem('now') >= electrical_power_window.end &&
        +localStorage.getItem('now') <= electrical_power_window.start)
    );
  }

  static getDeviceStatusByPieChart(device){
    const hasElectricalPower = Utils.hasElectricalPower();

    if (device['sys___active'] === -1) return 'Maintenance';
    if (device['sys___active'] === -9) return 'Fault In Care';
    if (device['sys___active'] === -3) return 'In installation';
    if (device['sys___active'] === 0) {
      if (!hasElectricalPower || (device.hasOwnProperty('disconnect_alert') && +device['disconnect_alert']) === 1) {
        return 'Electrical fault';
      }
      if (hasElectricalPower && device.hasOwnProperty('disconnect_alert') && +device['disconnect_alert'] !== 1){
        return 'Active devices';
      }
    }
    return 'Active devices';
  }

  static getCommissioned(device) {
    const metaCommission = device['meta.commission'];
    if (metaCommission) {
      if (typeof metaCommission.commissioned === 'string') {
        if (metaCommission.commissioned === 'true') {
          metaCommission.commissioned = true;
          return [true, metaCommission];
        }
      } else if (metaCommission.commissioned) {
        return [true, metaCommission];
      }
      metaCommission.commissioned = false;
      return [false, metaCommission];
    }
  }

  static checkCommissioned(meta_commission) {
    if (meta_commission) {
      if (typeof meta_commission.commissioned === 'string') {
        meta_commission.commissioned = meta_commission.commissioned === 'true' ? true : false;
      }
      return meta_commission.commissioned;
    }
    return false;
  }

  static isValidLocation(coordinates) {
    return (
      coordinates && 
      coordinates.lng &&
      coordinates.lng !== '0.0' &&
      coordinates.lat &&
      coordinates.lat !== '0.0' &&
      !isNaN(parseFloat(coordinates.lat)) &&
      !isNaN(parseFloat(coordinates.lng))
    );
  }

  static checkCabinetId(value){
    return /^[A-Za-z0-9][A-Za-z0-9]*[:/\\-][A-Za-z0-9][A-Za-z0-9]*$/.test(value) || 
      /^[A-Za-z0-9][A-Za-z0-9]*$/.test(value) || 
      i18n.t('This field must be in format') + ` [A-Z a-z 0-9][\\-/:][A-Z a-z 0-9]` + i18n.t('or') + ` [A-Z a-z 0-9]`;
  }

  static deviceLocation(device) {
    const metaDevice = device['meta.device'];
    const manualAddress = metaDevice && metaDevice.address;
    return `${(+device.lat).toFixed(5)}, ${(+device.lng).toFixed(5)} ${
      manualAddress ? manualAddress : device.geocode ? device.geocode : ''
    }`;
  }

  static convertUtcTimeToTimezone(time, timezone, format){
    const [hour, minute] = time.split(':');
    const today_time = moment().utcOffset(0).set({hour: +hour, minute: +minute}).valueOf();
    return moment(today_time).tz(timezone).format(format);
  }

  static convertTimezoneTimeToUtc(time, format){
    const [hour, minute] = time.split(':');
    const today_time = moment().set({hour: +hour, minute: +minute}).valueOf();
    return moment(today_time).utcOffset(0).format(format);
  }

  static isCabinet(device) {
    return (
      device['meta.category.category'] === 'HUB' ||
      device['meta.category.category'] === 'CABINET'
    );
  }

  static getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  static findProp(data, name) {
    let prop;
    data.results.forEach((result) => {
      if (result.name === name) {
        prop = result.value;
      }
    });
    return prop;
  }

  static convertPeriodToTimestamp(period) {
    const tsNow = Math.round(new Date().getTime() / 1000);
    let currentTimeStamp;
    const tsDayAgo = tsNow - 24 * 3600;
    const tsWeekAgo = tsNow - 24 * 7 * 3600;
    const tsMonthAgo = tsNow - 24 * 30 * 3600;
    const tsYearAgo = tsNow - 24 * 365 * 3600;

    switch (period) {
      case 'All':
        currentTimeStamp = 0;
        break;
      case 'Last 24 hours':
        currentTimeStamp = tsDayAgo;
        break;
      case 'Last week':
        currentTimeStamp = tsWeekAgo;
        break;
      case 'Last month':
        currentTimeStamp = tsMonthAgo;
        break;
      case 'Last year':
        currentTimeStamp = tsYearAgo;
        break;

      default:
        currentTimeStamp = 0;
        break;
    }
    return currentTimeStamp;
  }

  static convertDateToTimestamp(date) {
    if (date === null) {
      return null;
    }
    return moment(date).format('X');
  }

  static convertTimestamp(timestamp, format, timezone) {
    return moment(timestamp)
      .tz(timezone)
      .format(format);
  }

  static convertTimestampToDate(timestamp) {
    return moment.unix(timestamp).format('DD-MM-YYYY') || 'Invalid Date';
  }

  static convertTimestampToTime(timestamp) {
    return moment.unix(timestamp).format('HH:mm:ss') || 'Invalid time';
  }

  static getFullDateFormat() {
    return `YYYY-MM-DD HH:mm A`;
  }

  static convertTimeToMinutes(time){
    const without_sign = time.includes('+') || time.includes('-') ? time.slice(1) : time;
    const [hours, minutes] = without_sign.split(':');
    return (+hours * 60) + (+minutes);
  }

  static addToTimestamp(timestamp, period, value, timezone) {
    const formatted = this.convertTimestamp(
      timestamp,
      this.getFullDateFormat(),
      timezone
    );
    return (
      Number(
        Utils.convertDateToTimestamp(
          moment(formatted, this.getFullDateFormat()).add(period, value)
        )
      ) * 1000
    );
  }

  static addToTimestamp_string(timestamp, period, value, format, timezone) {
    const formatted = this.convertTimestamp(
      timestamp,
      this.getFullDateFormat(),
      timezone
    );
    return moment(formatted, this.getFullDateFormat())
      .add(value, period)
      .tz(timezone)
      .format(format);
  }

  static isDateBetween(date, date1, date2, range) {
    return moment(date).isBetween(date1, date2, undefined, range);
  }

  static removeFromTimestamp(timestamp, period, value, timezone) {
    const formatted = this.convertTimestamp(
      timestamp,
      this.getFullDateFormat(),
      timezone
    );
    return (
      Number(
        Utils.convertDateToTimestamp(
          moment(formatted, this.getFullDateFormat()).subtract(period, value)
        )
      ) * 1000
    );
  }

  static removeFromTimestamp_string(
    timestamp,
    period,
    value,
    format,
    timezone
  ) {
    const formatted = this.convertTimestamp(
      timestamp,
      this.getFullDateFormat(),
      timezone
    );
    return moment(formatted, this.getFullDateFormat())
      .subtract(value, period)
      .tz(timezone)
      .format(format);
  }

  static calculateDateDifference(timestamp1, timestamp2, unit) {
    const unitsJson = {
      hours: 'asHours',
      minutes: 'asMinutes',
      seconds: 'asSeconds'
    };

    const duration = moment.duration(
      moment(timestamp2).diff(moment(timestamp1))
    );
    const difference = duration[unitsJson[unit]]();
    return difference;
  }

  static convertCommissionDate(date, format) {
    if (date === 'none') return '';

    const converted = date.includes('T')
      ? moment(date).format(format)
      : date.includes('.')
      ? moment(date, 'DD.MM.YYYY HH:mm:ss').format(format)
      : moment(date, 'MM-DD-YYYY HH:mm:ss').format(format);

    return converted;
  }

  static convertErrorCodeToMessage(code) {
    const errorTypes = {
      0: 'NO_FAULT',
      1: 'TEMP_SESOR_ERROR',
      2: 'TEMP_TOO_HIGH',
      3: 'TONDO_UNIT_POWER_TOO_LOW',
      4: 'DRIVER_FAULT',
      5: 'LED_LIGHTS_FAULT',
      6: 'DRIVER_AUTOCONF_FAULT',
      7: 'UNIT_BAD_ANDGLE_FAULT'
    };
    return errorTypes[code] || 'Other error';
  }

  static async convertDimmerValues(deviceId) {
    let converted = false;
    const daliToPercentTable = {};
    const response = await API.get(
      'devices',
      deviceId + '/properties',
      { name: 'device.dimmer_converting_table' }
    );
    const percentToDaliTable = response && response.results
      ? response.results[0].value
      : null;
    if (percentToDaliTable) {
      this.getDaliToPercentTable(percentToDaliTable, daliToPercentTable);
      converted = true;
    }
    return [converted, percentToDaliTable, daliToPercentTable];
  }

  static getDaliToPercentTable(percentToDaliTable, daliToPercentTable) {
    const daliArray = Object.values(percentToDaliTable);
    const swapTable = this.swapValues(percentToDaliTable);
    daliToPercentTable[0] = '0';
    for (let i = 1; i <= 254; i++) {
      if (daliArray.includes(i)) {
        daliToPercentTable[i] = swapTable[i];
      } else {
        daliToPercentTable[i] = daliToPercentTable[i - 1];
      }
    }
  }

  static swapValues(percentToDaliTable) {
    const res = {};
    Object.keys(percentToDaliTable)
      .reverse()
      .forEach((key) => {
        res[percentToDaliTable[key]] = key;
      });
    return res;
  }

  static getPercentValue(converted, daliToPercentTable, daliValue) {
    return converted
      ? daliToPercentTable[daliValue]
      : Math.round((daliValue / 254) * 100);
  }

  static hasCabinetClass(class_name) {
    if (!class_name) return false;

    const class_name_lowercase = class_name.toLowerCase();
    return (
      class_name_lowercase.includes('power_meter_hummingboard') ||
      class_name_lowercase.includes('cabinet-monitor') ||
      class_name_lowercase.includes('sbc_cabinet')
    );
  }

  static hasVirtualCabinetClass(class_name, meta_device) {
    const class_name_lowercase = class_name && class_name.toLowerCase();
    return class_name_lowercase && class_name_lowercase.includes('virtual_cabinet') ||
      meta_device && meta_device.cabinet_type && meta_device.cabinet_type.toLowerCase() === 'virtual'
      ? true 
      : false;
  }
  
  static hasCabinetClassWithNewFormat(device_version) {
    if (!device_version || !device_version.os_release) return false;

    const min_first_digit = 1, min_second_digit = 9, min_third_digit = 8;
    const last_undersco_index = device_version.os_release.lastIndexOf('_');
    if (last_undersco_index === -1) return false;

    const [first, second, third] = device_version.os_release.slice(last_undersco_index + 1).split('.');
    if (+first > min_first_digit) return true;
    if (+first < min_first_digit) return false;
    if (+second > min_second_digit) return true;
    if (+second < min_second_digit) return false;
    if (+third >= min_third_digit) return true;
    return false;
  }

  static displayNewConnectionsScreen(cabinet_version, cabinet_connections){
    return Utils.hasCabinetClassWithNewFormat(cabinet_version) && 
      (Array.isArray(cabinet_connections) || (typeof cabinet_connections === 'object' && !Object.keys(cabinet_connections).length));
  }

  static hasExtentionedTeltonicaClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('sbc_cabinet_extensions');
  }

  static hasExtentionCardClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('extension_card');
  }

  static hasTondoClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('nrf') && !class_name.toLowerCase().includes('flood_sensor') && !class_name.toLowerCase().includes('barrier') ||
      class_name === 'SC220_ZHAGA' || class_name === 'SC220_NEMA';
  }

  static hasMotionSensorClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('sensor') && !(class_name.toLowerCase().includes('flood_sensor'));
  }

  static hasFloodSensorClass(class_name, category) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('flood_sensor') && category.toLowerCase() === 'flood sensor';
  }

  static hasRuleBoardClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('rule_board');
  }

  static hasBarrierClass(class_name, category) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('flood_sensor') && category.toLowerCase() === 'barrier';
  }

  static hasCameraClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('camera');
  }

  static hasAssetClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('asset');
  }

  static hasVideoClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase().includes('video');
  }

  static hasWaterMeterClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase() === 'water_meter';
  }

  static hasWeatherStationClass(class_name) {
    if (!class_name) return false;

    return class_name.toLowerCase() === 'weather_station';
  }

  static hasRadarClass(class_name) {
    return class_name.toLowerCase() === 'radar';
  }

  static isRegularFixture(fixture_type) {
    return !fixture_type || fixture_type.toLowerCase() === 'regular';
  }

  static isVirtualFixture(fixture_type) {
    return fixture_type && fixture_type.toLowerCase() === 'virtual';
  }

  static isUndergroundFixture(fixture_type) {
    return fixture_type && fixture_type.toLowerCase() === 'underground';
  }

  static isHighmastFixture(fixture_type) {
    return fixture_type && fixture_type.toLowerCase() === 'highmast';
  }

  static isSecurityFixture(fixture_type) {
    return fixture_type && fixture_type.toLowerCase() === 'security';
  }

  static isElementOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  static getTondoIconType(device) {
    const meta_device = device['meta.device'];
    return (
      (meta_device['fixture_icon'] &&
        meta_device['fixture_icon'].toLowerCase()) ||
      'regular'
    );
  }

  static getDeviceType(class_name) {
    return Utils.hasTondoClass(class_name)
      ? 'fixture'
      : Utils.hasCabinetClass(class_name) || Utils.hasCabinetClass(class_name)
      ? 'cabinet'
      : Utils.hasCameraClass(class_name)
      ? 'camera'
      : 'unknown';
  }

  static getStream(stream_name, streams){
    const stream = streams && streams.find((stream) => stream.name === stream_name);
    return stream || null;
  }

  static getLiveDataValue(live_data_value){
    let parsed_value = null;
    if (typeof live_data_value === 'string'){
      try {
        parsed_value = live_data_value;
        parsed_value = JSON.parse(parsed_value.replaceAll(`'`, `"`));
      }catch (e){
        parsed_value = {};
      }
    }
    return parsed_value;
  }

  static getFilteredColumns(all_columns, current_columns){
    return all_columns
      .filter((column) => current_columns.indexOf(column.column_name) !== -1)
      .map((column) => column.column_name);
  }

  static async fetchStreamLatestValue(device_id, stream_name) {
    try {
      const response = await API.get(Types.DEVICES, `${device_id}/streams/${device_id}.${stream_name}/latest`);
      return response || null;
    } catch (e) {
      return null;
    }
  }
  
  static async fetchSingleStreamHistory({ device, stream, period }) {
    const response = await API.get(
      Types.DEVICES,
      `${device.id}/streams/${stream.id}/history/`,
      { since: period },
      {}
    );
    if (response && Array.isArray(response)) {
      return response;
    }
    return [];
  }

  static getAstronomicalTime(string_offset_time, timestamp_time, moment_time, timezone, format = 'HH:mm'){
    const offset_sign = string_offset_time.includes('+') || string_offset_time.includes('-') ? string_offset_time.includes('+') ? '+' : '-' : '+';
    const offset_minutes = Utils.convertTimeToMinutes(string_offset_time) * (offset_sign === '+' ? 1 : -1);
    return Utils.getAstronomicalTimeByMinutesOffset(offset_minutes, timestamp_time, moment_time, timezone, format);
  }

  static getAstronomicalTimeByMinutesOffset(offset_in_minutes, timestamp_time, moment_time, timezone, format = 'HH:mm') {
    return offset_in_minutes
      ? offset_in_minutes > 0 
        ? Utils.addToTimestamp_string(timestamp_time, 'minutes', offset_in_minutes, format, timezone)
        : Utils.removeFromTimestamp_string(timestamp_time, 'minutes', Math.abs(offset_in_minutes), format, timezone)
      : moment_time.format(format);
  }

  static isValidEmailAddress(email){
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  static isValidName(name, prefix){
    if (!/^[a-zA-Z\s-]+$/.test(name)){
      return `${i18n.t(`${prefix} name must contains only the characters`)} [a-z][A-Z][-] ${i18n.t('and spaces')}`;
    }

    return true;
  }

  
  static isValidPhoneNumber(phone_number){
    if (!phone_number) {
      return i18n.t('Required');
    }

    if (isNaN(phone_number.slice(1, phone_number.length)) || phone_number[0] !== '+') {
      return (vuex.state as any).Global.lang === 'en' 
        ? `Phone number must include only digits and intl. code, Example +972000000000`
        : `${i18n.t('Phone number must include only digits and intl')}, ${i18n.t('Example')} +972000000000`;
    }

    if (phone_number.length < 12) {
      return i18n.t('Phone number is too short');
    }

    return true;
  }

  static checkContainsHebrewLetters(str){
    return str.match(/[\u0590-\u05FF]/);
  }

  static getProjectTicketsList(project_tickets_data){
    if (!project_tickets_data || !project_tickets_data.cabinets) return [];

    return Object.values(project_tickets_data.cabinets).map((cabinet_tickets_data) => cabinet_tickets_data['list']).flat();
  }

  static getTimeByTimezone(time, timezone){
    const offset_in_minutes = moment().tz(timezone).utcOffset();
    const formatted_time = time.split(':')[0].length === 1 ? '0' + time : time;
    return moment(formatted_time, 'HH:mm').add(offset_in_minutes, 'minutes').format('HH:mm');
  }

  static getTodayScheduleTime(schedules, output_num){
    const day_of_week = (vuex.state as any).Global.day_of_week;
    const today_schedule = schedules.hasOwnProperty('schedule') && schedules.schedule.hasOwnProperty(output_num) && schedules.schedule[output_num].active
      ? {...schedules.schedule[output_num].time_points[0]}
      : {on: '', off: ''};
    
    const formatted_today_schedule = {
      on_time: Utils.getOnTimePoint(today_schedule),
      off_time: Utils.getOffTimePoint(today_schedule),
      clock_type: Utils.getClockType(today_schedule)
    };
    
    if (schedules.hasOwnProperty('days_schedule') && 
      schedules.days_schedule.hasOwnProperty(output_num) &&
      schedules.days_schedule[output_num].some((schedule) => schedule.days.includes(day_of_week) && schedule.active)
    ) {
      const current_day_schedule = schedules.days_schedule[output_num].find((schedule) =>
        schedule.days.includes(day_of_week) && schedule.active
      );

      const max_on_time_index = Utils.findIndexOfMaxTime(current_day_schedule.time_points, true, 'on');

      if (max_on_time_index === -1) return formatted_today_schedule;

      if (max_on_time_index === current_day_schedule.time_points.length - 1){ // one time point
        const next_day_num = Utils.getNextDay(day_of_week);
        const next_day_data = schedules.days_schedule[output_num].find((days_schedule) =>
          days_schedule.days.includes(next_day_num) && days_schedule.active
        );
        if (next_day_data) {
          const min_off_time_index = Utils.findIndexOfMinTime(next_day_data.time_points, true, 'off');

          if (min_off_time_index === -1) return formatted_today_schedule;
          today_schedule.off = {...next_day_data.time_points[min_off_time_index].off};
        }
      }else {
        const max_off_time_index = Utils.findIndexOfMaxTime(current_day_schedule.time_points, true, 'off');
        today_schedule.off = {...current_day_schedule.time_points[max_off_time_index].off};
      }
      today_schedule.on = {...current_day_schedule.time_points[max_on_time_index].on};
    }

    return {
      on_time: Utils.getOnTimePoint(today_schedule),
      off_time: Utils.getOffTimePoint(today_schedule),
      clock_type: Utils.getClockType(today_schedule)
    };
  }

  static getClockType(time_point){
    return !time_point.on || !time_point.off 
      ? ''
      : time_point.on.type === 'dusk' && time_point.off.type === 'dawn'
        ? 'Astral'
        : time_point.on.type === 'time' && time_point.off.type === 'time'
          ? 'Clock'
          : 'Combined';
  }

  static findIndexOfMinTime(time_points, find_custom, field_name){
    let min_time_in_minutes = 1440, min_index = -1;
    const dusk_dawn_data = (vuex.state as any).Global.dusk_dawn_data;

    const astronomical_timestamp = field_name === 'on' ? dusk_dawn_data.dusk_timestamp : dusk_dawn_data.dawn_timestamp;
    const astronomical_moment = field_name === 'on' ? dusk_dawn_data.dusk : dusk_dawn_data.dawn;
    time_points.forEach((point, index) => {
      if (find_custom && point[field_name].default) return;

      const time_in_minutes = Utils.convertTimePointToMinutes(point[field_name], astronomical_timestamp, astronomical_moment);
      if (time_in_minutes < min_time_in_minutes) {
        min_time_in_minutes = time_in_minutes;
        min_index = index;
      }
    });
    return min_index;
  }

  static getNextDay(current_day){
    return current_day + 1 > 7 ? 1 : current_day + 1;
  }

  static convertTimePointToMinutes(time_data, astronomical_timestamp, astronomical_moment) {
    const formatted_time = this.getFormattedTimePoint(time_data, astronomical_timestamp, astronomical_moment);
    return Utils.convertTimeToMinutes(formatted_time);
  }

  // return time point in format 'HH:MM'
  static getFormattedTimePoint(time_data, astronomical_timestamp, astronomical_moment) {
    const timezone = (vuex.state as any).Global.timezone || (vuex.state as any).Global.allProjectsTimezone || 'Asia/Tel_Aviv';

    return time_data.type === 'dusk' || time_data.type === 'dawn'
      ? Utils.getAstronomicalTime(time_data.value, astronomical_timestamp, astronomical_moment, timezone)
      : time_data.value;
  }

  static findIndexOfMaxTime(time_points, find_custom, field_name){
    let max_time_in_minutes = 0, max_index = -1;
    const dusk_dawn_data = (vuex.state as any).Global.dusk_dawn_data;

    const astronomical_timestamp = field_name === 'on' ? dusk_dawn_data.dusk_timestamp : dusk_dawn_data.dawn_timestamp;
    const astronomical_moment = field_name === 'on' ? dusk_dawn_data.dusk : dusk_dawn_data.dawn;
    time_points.forEach((point, index) => {
      if (find_custom && point[field_name].default || !find_custom && !point.on.default) return;

      const time_in_minutes = Utils.convertTimePointToMinutes(point[field_name], astronomical_timestamp, astronomical_moment);

      if (time_in_minutes > max_time_in_minutes) {
        max_time_in_minutes = time_in_minutes;
        max_index = index;
      }
    });
    return max_index;
  }

  static getOnTimePoint(time_point){
    const timezone = (vuex.state as any).Global.timezone || (vuex.state as any).Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    const dusk_dawn_data = (vuex.state as any).Global.dusk_dawn_data;

    return time_point && time_point.on
      ? time_point.on.type === 'time'
        ? time_point.on.value
        : Utils.getAstronomicalTime(time_point.on.value, dusk_dawn_data.sunset_start_timestamp, dusk_dawn_data.sunset_start, timezone)
      : '';
  }

  static getOffTimePoint(time_point){
    const timezone = (vuex.state as any).Global.timezone || (vuex.state as any).Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    const dusk_dawn_data = (vuex.state as any).Global.dusk_dawn_data;

    return time_point && time_point.off
      ? time_point.off.type === 'time'
        ? time_point.off.value
        : Utils.getAstronomicalTime(time_point.off.value, dusk_dawn_data.sunrise_timestamp, dusk_dawn_data.sunrise, timezone)
      : '';
  }

  static numberWithCommas(number){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  static handleNotAllowedUser() {
    vuex.commit('Global/setNotAllowedUserDialog', true);
  }

  static containsHebrew(str) {
    return (/[\u0590-\u05FF]/).test(str);
  }

  static getDeviceAddress(address, estimated){
    return address || (estimated && `${estimated} (${i18n.t('estimated')})`) || i18n.t('N/A');
  }

  static getAlertPriority(priority_id) {
    return priority_id === 1
      ? 'Low'
      : priority_id === 2
      ? 'Medium'
      : 'High';
  }

  static getTicketDescription(description){
    const split_length = description.includes(':') && description.split(':').length || 0;
    const parsed_description = description.includes(':') && description.split(':').length > 1
      ? description.split(':')[split_length - 1].trim()
      : description;
    return PropUtils.getFieldLowerToUpper(parsed_description);
  }

  static getNow(timezone){
    return Utils.convertTimestamp(moment().utcOffset(0).valueOf(), 'DD.MM.YYYY HH:mm', timezone);
  }

  static getIdleTimeInMinutes() {
    const current_time = momentTZ().utcOffset(0).valueOf();
    const last_time_seen = Number(localStorage.getItem('last_time_seen'));
    return Math.abs(current_time - last_time_seen) / 1000 / 60;
  }

  static getCabinetManagedArea(meta_device){
    return meta_device.managed_area || meta_device.road || i18n.t('N/A');
  }
  
  static getAnalyticsAlertsServiceStatus(cabinet, timezone){
    const services_config = PropUtils.parseProperty('services.config', cabinet);
    const alert_config = services_config.hasOwnProperty('alerts') ? services_config['alerts'] : {};
    
    if (!Object.keys(alert_config).length) return [false, i18n.t('Alerts service does not exist')];
    const alerts_service_is_on = !alert_config.kill_switch_analytics_alerts;
    
    if (!alerts_service_is_on) return [false, i18n.t('Alerts service is inactive')];

    const window = Utils.getAlertsWindowByTimestamp(alert_config.window);
    if (window){
      const start_by_timezone = Utils.convertTimestamp(window.start, 'HH:mm', timezone);
      const end_by_timezone = Utils.convertTimestamp(window.end, 'HH:mm', timezone);
      if (Utils.getAlertsTimeRange(alert_config.window)) {
        const range = (vuex.state as any).Global.lang === 'en' && `${start_by_timezone} - ${end_by_timezone}` || `${end_by_timezone} - ${start_by_timezone}`;
        return [true, `${i18n.t('Alerts active during the night')} ${range}`];
      }
    }
    return [true, i18n.t('24/7 Alert Service')];
  }

  static getCabinetSatecAlertsServiceStatus(cabinet){
    const services_config = PropUtils.parseProperty('services.config', cabinet);
    const alert_config = services_config.hasOwnProperty('alerts') ? services_config['alerts'] : {};
    if (Object.keys(alert_config).length){
      return alert_config.kill_switch_cabinet_alerts 
        ? [false, i18n.t('Cabinet IQ alerts service is off')]
        : [true, i18n.t('Cabinet IQ alerts service is on')];
    }
    return [false, i18n.t('Cabinet IQ alerts service does not exist')];
  }

  static getPowerValidationServiceStatus(cabinet){
    const services_config = PropUtils.parseProperty('services.config', cabinet);
    const schedule_config = services_config.hasOwnProperty('schedule_validation') ? services_config['schedule_validation'] : {};
    if (schedule_config.hasOwnProperty('kill_switch')) {
      return schedule_config.kill_switch 
        ? [false, i18n.t('Power service is off')]
        : [true, i18n.t('Power service is on')];
    }
    return [false, i18n.t('Power service not exist')];
  }

  static getAlertsWindowByTimestamp(window){
    if (window && Object.keys(window).length && window.start && window.end) {
      const alert_window = {...window};
      alert_window.start = Utils.getDateWithSpecificTime(window.start, +localStorage.getItem('now'));
      alert_window.end = Utils.getDateWithSpecificTime(window.end, +localStorage.getItem('now'));
      return alert_window;
    }
    return null;
  }

  static getAlertsTimeRange(window){
    const [start_hours, start_minutes] = window.start.split(':');
    const [end_hours, end_minutes] = window.end.split(':');
    const start_timestamp = moment().utcOffset(0).set({hour: +start_hours, minute: +start_minutes}).valueOf();
    const end_yesterdat_timestamp = moment().utcOffset(0).subtract(1, 'days').set({hour: +end_hours, minute: +end_minutes}).valueOf();
    const end_timestamp = moment().utcOffset(0).set({hour: +end_hours, minute: +end_minutes}).valueOf();
    
    const difference1 = Math.abs(start_timestamp - end_yesterdat_timestamp);
    const difference2 = Math.abs(start_timestamp - end_timestamp);
    if (difference1 > 40 * 60000 && difference2 > 40 * 60000){ // convert 40 minutes to milliseconds
      return true;
    }
    return false;
  }

  static getCabinetDevicesSchedule(cabinet, timezone) {
    const cabinet_schedule = PropUtils.parseProperty('meta.devices_schedule', cabinet);
    const dusk_dawn_data = (vuex.state as any).Global.dusk_dawn_data;

    const schedule_service = {
      is_astronomical: false,
      start_time: '',
      end_time: '',
      active: false,
      exist: false
    };

    if (cabinet_schedule && typeof cabinet_schedule === 'object' && Object.entries(cabinet_schedule).length){
      schedule_service.exist = true;
      schedule_service.active = cabinet_schedule.active;
      schedule_service.is_astronomical = cabinet_schedule.is_astronomical;

      if (cabinet_schedule.is_astronomical){
        schedule_service.start_time = Utils.getAstronomicalTime(cabinet_schedule.dusk, dusk_dawn_data.dusk_timestamp, dusk_dawn_data.dusk, timezone);
        schedule_service.end_time = Utils.getAstronomicalTime(cabinet_schedule.dawn, dusk_dawn_data.dawn_timestamp, dusk_dawn_data.dawn, timezone);
      }else {
        schedule_service.start_time = Utils.convertUtcTimeToTimezone(cabinet_schedule.on, timezone, 'HH:mm');
        schedule_service.end_time = Utils.convertUtcTimeToTimezone(cabinet_schedule.off, timezone, 'HH:mm');
      }
    }

    if (schedule_service.exist) {
      if (!schedule_service.active){
        return [false, i18n.t('Devices schedule is off')];
      }
      const range = (vuex.state as any).Global.lang === 'en' && `${schedule_service.start_time} - ${schedule_service.end_time}` || `${schedule_service.end_time} - ${schedule_service.start_time}`;
      if (cabinet_schedule.is_astronomical) {
        return [true, `${i18n.t('Astronomical')} ${range}`];
      }
      return [true, `${i18n.t('Custom')} ${range}`];
    }
    return [false, i18n.t('Devices schedule not exist')];
  }

  static convertNumberToRealPhase(phase_num) {
    return phase_num === '1' && 'R' || phase_num === '2' && 'S' || phase_num === '3' && 'T' || phase_num.toUpperCase();
  }

  static getRelativeTime(timestamp) {
    return moment(timestamp).fromNow();
  }

  static translateRelativeTime(relative_time){
    if ((vuex.state as any).Global.lang === 'he'){
      let number = relative_time.match(/\d+/);
      if (number !== null){
        number = number[0];
        const relative_without_numbers = relative_time.replace(/\d+/g, '').trim();
        return i18n.t(`{{number}} ${relative_without_numbers}`, { number });
      }
      return i18n.t(relative_time);
    }
    return relative_time;
  }

  static removeLeadingZeroesAndSpaces(value) {
    let parsed_value = typeof value === 'string' && value.trim() || value;

    if (typeof parsed_value === 'string' && parsed_value && !Number.isNaN(+parsed_value)) {
      return (+parsed_value).toString(); //for removing leading zeroes
    }
    return parsed_value.toString();
  }
}

