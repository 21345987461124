


















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, User, Companies, Props, Reports, Projects } from '@/store';
import MQTT, { EventTypes, StateTypes } from '@/modules/MQTT';
import SearchMenu from '@/components/SearchMenu.vue';
import Utils from '@/modules/Utils';
import store from 'store';

export enum States {
  REST = 'rest',
  ERROR = 'error',
  RECEIVE = 'receive',
  PUBLISH = 'publish'
}

@Component({
  components: {
    SearchMenu
  }
})
export default class Header extends Vue {
  @Global.State('isMobile') isMobile;
  @Global.State('pageTitle') pageTitle;
  @Global.State('lang') lang;
  @Global.State('isSidebarOpen') isSidebarOpen;
  @Global.State('timezone') projectTimezone;
  @Global.Action('changeLang') changeLang;
  @User.State('isAuth') isAuth;
  @User.State('project') project;
  @User.State('username') username;
  @User.Mutation('logout') logout;
  @User.Mutation('clearCompanyData') clearCompanyData;
  @User.Action('fetchProjectsData') fetchProjectsData;
  @Props.State('list') projectProperties;
  @Reports.State('projectId') projectId;
  @Projects.State('alerts') allAlerts;
  @Projects.State('list') projectsList;
  @Projects.State('images') images;
  @Companies.State('companies_list') companies_list;
  @Companies.State('selected_company') selected_company;
  @Companies.Mutation('setSelectedCompany') setSelectedCompany;

  @Prop() time;

  count = 0;
  projectAlerts = 0;
  online = false;
  state: States = States.REST;
  stateChangeDelay = 1500;
  stateCallback = null;
  logoURL = '';
  box_shadow = '0px 2px 3px';
  navigationStyles = {};
  location = null;
  critical_flood_alerts = null;

  updated() {
    this.location = this.$route.path;
  }

  mounted() {
    if (this.isAuth) {
      this.setLang(this.lang);
    }

    this.online = MQTT.instance.state === StateTypes.ONLINE;
    MQTT.instance.on(
      EventTypes.REDUCTION,
      (state) => (this.online = state === StateTypes.ONLINE)
    );
    MQTT.instance.on(EventTypes.RECEIVE, (msg) =>
      this.setState(States.RECEIVE)
    );
    MQTT.instance.on(EventTypes.PUBLISH, (msg) =>
      this.setState(States.PUBLISH)
    );
    MQTT.instance.on(EventTypes.ERROR, (msg) => this.setState(States.ERROR));
    MQTT.instance.on(
      EventTypes.SUBSCRIBE,
      (msg) => (this.count = MQTT.instance.countOfListeners)
    );

    this.setBoxShadow();
  }
  
  @Watch('companies_list')
  get companies(){
    return Object.entries(this.companies_list);
  }

  changeCompany(company_id) {
    this.clearCompanyData();
    const user = store.get('user');
    store.set('user', {...user, company: company_id});
    this.setSelectedCompany(company_id);
    this.$router.push('/projects');
    this.fetchProjectsData();
  }

  updateLogoAndFavicon() {
    const logoProp = this.projectProperties.find(
      (el) => el.name === 'dashboard.logo'
    );
    if (logoProp) {
      this.logoURL = logoProp.value.url;
      if (logoProp.value.favicon) {
        document
          .querySelector('#favicon')
          .setAttribute('href', logoProp.value.favicon);
      }
    } else {
      this.logoURL = '';
    }
  }

  @Watch('projectProperties')
  async updateLogo() {
    this.updateLogoAndFavicon();
  }

  setState(state: States) {
    this.state = state;
    if (this.stateCallback) clearTimeout(this.stateCallback);
    this.stateCallback = setTimeout(
      () => (this.state = States.REST),
      this.stateChangeDelay
    );
  }

  @Watch('project')
  @Watch('images', {deep: true})
  setBoxShadow(){
    const color = Object.keys(this.images).length && this.project && this.images[this.project.id] 
      ? this.images[this.project.id].color
      : 'rgba(0, 0, 0, 0.05)';
    
    Vue.set(
      this.navigationStyles,
      'box-shadow',
      `${this.box_shadow} ${color}`
    );
  }

  getDateByTimezone(timestamp){
    return Utils.convertTimestamp(timestamp, 'DD/MM/YYYY HH:mm', this.projectTimezone);
  }

  @Watch('lang')
  async setLang(lang) {
    await this.$i18n.i18next.changeLanguage(lang);
    this.$vuetify.rtl = lang !== 'en';
  }

  @Watch('projectId')
  @Watch('allAlerts', {immediate: true, deep: true})
  @Watch('projectsList')
  setAlerts() {
    if (!this.projectsList.length || !this.projectId) return;
    if (this.projectId === 'allProjects') {
      const cabinets_alerts_by_project = Object.values(this.allAlerts).map((project_alerts_data) => Object.keys(project_alerts_data['cabinets']).length);
      this.projectAlerts = cabinets_alerts_by_project.reduce((accumulator, alerts_count) => accumulator + alerts_count , 0);

      const flood_alerts_all_projects = Object.values(this.allAlerts).map((project_alerts_data) =>
        project_alerts_data['general_list'].filter((alert) => alert.type.toLowerCase().includes('flood') &&  alert.priority_id === 3)
      ).flat();

      if (flood_alerts_all_projects.length) {
        this.critical_flood_alerts = flood_alerts_all_projects.length > 1 && flood_alerts_all_projects.some((alert) => alert.title.includes('road'))
          ? flood_alerts_all_projects.find((alert) => alert.title.includes('road'))
          : flood_alerts_all_projects[0];   
      }else {
        this.critical_flood_alerts = null;
      }
      return;
    }

    const project_alerts_list_by_cabinet = this.allAlerts[this.projectId] && this.allAlerts[this.projectId].cabinets || {};

    this.projectAlerts = Object.keys(project_alerts_list_by_cabinet).length;
    const flood_alerts = this.allAlerts[this.projectId] && this.allAlerts[this.projectId].general_list.filter((alert) => alert.alert_type.toLowerCase().includes('flood') && alert.priority_id === 3);
    if (flood_alerts.length){
      this.critical_flood_alerts = flood_alerts.length > 1 && flood_alerts.some((alert) => alert.title.includes('road'))
        ? flood_alerts.find((alert) => alert.title.includes('road'))
        : flood_alerts[0];
    }else {
      this.critical_flood_alerts = null;
    }
  }

  async switchLang(lang) {
    await this.changeLang({
      username: this.username,
      lang,
      project: this.project
    });
  }
}
